<template>
  <div class="SupplierList">
    <ul class="listBox">
      <li class="item" v-for="(item, index) in listSupplier" :key="index">
        <!-- logo -->
        <van-row
          type="flex"
          align="center"
          class="infoBox"
          @click="getDetailsInfo(item.CustomerId)"
        >
          <van-col class="infoBoxLeft">
            <figure class="logo">
              <van-image
                class="logoIcon"
                :src="
                  item.CustomerHeadPhoto && imgUrlFilter(item.CustomerHeadPhoto)
                "
              >
                <template v-slot:error>
                  <img
                    class="ImgIcon"
                    src="~assets/img/userManage/user_logo@2x.png"
                  />
                </template>
              </van-image>
            </figure>
          </van-col>
          <van-col class="infoBoxRigth">
            <p class="companyName">{{ item.CompanyName }}</p>
            <p class="productCount">
              <span class="num">{{ item.ProductCount }}</span>
              {{ $t.userInterpret.ProductSell }}
            </p>
          </van-col>
          <van-col class="infoBoxOperation">
            <van-icon name="arrow" size="20" />
          </van-col>
        </van-row>
      </li>
    </ul>
  </div>
</template>
<script>
import Vue from "vue";
import { Image, Icon } from "vant";
import { Row, Col } from "vant";
Vue.use(Row).use(Col);
Vue.use(Image);
Vue.use(Icon);

export default {
  name: "SupplierList",
  props: ["listSupplier"],
  components: {},
  data() {
    return {};
  },
  methods: {
    imgUrlFilter(value) {
      return this.$api.formatImageUrl(value.split("|")[0]);
    },
    //跳转供应商
    getDetailsInfo(customerId) {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: true,
        path: "drawableInfo",
        name: "DrawableInfo",
        query: [
          {
            name: "CustomerId",
            value: customerId,
          },
        ],
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@mixin spanNowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@mixin puiblicFlex {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
}
@mixin publicBt {
  cursor: pointer;
  -webkit-cursor: pointer;
  border-radius: 32px;
  padding: 12px 0;
  text-align: center;
}
.SupplierList {
  width: 100%;
  .listBox {
    margin: 0% 3%;
    // padding: 36px;
    // box-sizing: border-box;
    // -webkit-box-sizing: border-box;
    .item {
      padding: 15px;
      border-radius: 20px;
      box-shadow: 0 0 0px 1px #ece7e7;
      background-color: #ffffff;
      box-sizing: border-box;
      -webkit-box-sizing: border-box;
      margin-top: 15px;
      .itemTop {
        .itemTopTitle {
          width: 77%;
          font-size: 24px;
          flex: 1;
          -webkit-flex: 1;
          color: #000000;
          font-weight: bold;
        }
        .shelves {
          border: 1px solid #999999;
          width: 110px;
          text-align: center;
          border-radius: 32px;
          padding: 12px 0;
          color: #999999;
        }
        .putaway {
          border: 1px solid #ff625c;
          width: 110px;
          text-align: center;
          border-radius: 32px;
          padding: 15px 0;
          color: #ff625c;
        }
      }
    }
  }
  .infoBox {
    .infoBoxLeft {
      width: 20%;
      text-align: center;
      line-height: 0;
      .logo {
        margin: 0;
        .logoIcon {
          .ImgIcon {
            width: 55px;
            height: 55px;
            object-fit: cover;
            border-radius: 100%;
          }
          ::v-deep .van-image__img {
            width: 55px;
            height: 55px;
            object-fit: cover;
            position: relative;
            border-radius: 100%;
          }
          ::v-deep .van-image__error {
            width: 55px;
            height: 55px;
            object-fit: cover;
            position: relative;
            border-radius: 100%;
          }
        }
      }
    }
    .infoBoxRigth {
      width: 70%;
      padding-left: 0.4rem;
      padding-bottom: 0.15rem;
      line-height: 3em;
      .infoBoxRigthTitle {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        width: 100%;
        margin: 0;
        padding: 0;
      }
      .companyName {
        margin: 0;
        font-size: 14px;
        font-weight: bold;
        color: #000;
        line-height: 1;
        margin-bottom: 15px;
        @include spanNowrap;
      }
      .productCount {
        margin: 0;
        font-size: 14px;
        color: #999999;
        line-height: 1;
        @include spanNowrap;
        .num {
          color: #01aaa3;
        }
      }
    }
    .infoBoxOperation {
      width: 10%;
      color: #999999;
    }
  }
}
</style>
